export const AVATAR_SIZE = {
    me: { xs: 70, sm: 70 },
    other: { xs: 60, sm: 60 },
    leaderboard: { xs: 35, sm: 45 },
    endScreen: { xs: 100, sm: 100 },
};

export const COLORS = {
    wmTheme: "#00B3E2",
};

//constant pass when calling an socket emit or receive
export const SOCKET_ACTIONS = {
    SEND: true,
    RECEIVE: false,
};

export const TRUE_FALSE = [
    {
        id: "true",
        answer: "True",
    },
    {
        id: "false",
        answer: "False",
    },
];

//List of socket receive actions
export const SOCKET_RECEIVE_EVENTS = {
    SERVER_STATUS: "serverStatus",
    CONNECT_ERROR: "connect_error",
    CURRENT_USER: "currentUser",
    GAME_DETAILS: "gameDetails",
    PLAYER_JOINED: "playerJoined",
    PLAYER_REMOVED: "playerRemoved",
    PLAYER_ANSWERED: "playerAnswered",
    PLAYERS_UPDATE: "playersUpdate",
    START_COUNTDOWN: "startCountdown",

    QUESTION_COUNTDOWN: "questionCountdown",
    QUESTION_STARTS: "questionStarts",
    QUESTION_ENDS: "questionEnds",
    QUESTIONS_UPDATE: "questionsUpdate",

    ROUND_UPDATE: "roundUpdate",
    ROUND_COUNTDOWN: "roundCountdown",
    ROUND_INFO_UPDATE: "roundInfoUpdate",

    ROOM_FULL: "roomFull",
    GAME_ENDS: "gameEnds",

    GAME_ONGOING: "gameOngoing",
    PLAYER_DISCONNECTED: "playerDisconnected",
    HANDLE_ERROR: "handleError",

    UPDATE_STATUS: "updateStatus",
    WAITING_COUNTDOWN: "waitingCountdown",
    START_COUNTDOWN: "startCountdown",

    REDEEM_REQUESTED: "redeemRequested",
    REDEEM_ERROR: "redeemError",
    RESET_GAME: "resetGame",

    LOGIN_REQUIRED: "loginRequired",
    REQUIRE_POINTS: "requirePoints",

    INSUFFICIENT_POINTS: "insufficientPoints",
    REFRESH: "refresh",
    PLAYER_STATS: "playerStats",
    PLAYER_POINTS: "playerPoints",
    POWERUPS: "powerUps",
    POWERUP_SWAP: "powerUpSwap",
    SHOW_HINT: "showHint",

    SNACKBAR: "snackBar",

    UILABELS: "uiLabels",
};

//List of socket emit actions
export const SOCKET_SEND_EVENTS = {
    JOIN_QUEUE: "joinQueue",
    JOIN_ROOM: "joinRoom",
    LEAVE_ROOM: "leaveRoom",
    START_GAME: "startGame",
    ANSWER_QUESTION: "answerQuestion",
    UPDATE_SETTINGS: "updateSettings",
    SOLO_GAME: "soloGame",
    PLAY_AGAIN: "playAgain",
    ADD_BOT: "addBot",
    QUESTION_VOTE: "questionVote",
    REDEEM_PRIZE: "redeemPrize",
    DELETE_GAME: "deleteGame",
    UPDATE_AVATAR: "updateAvatar",
    PAUSE_GAME: "pauseGame",
    PLAYER_STATS: "playerStats",
    USE_POWER_UPS: "usePowerUps",

    QUESTIONS_UPDATE: "questionsUpdate",

    UILABELS: "uiLabels",
};

export const GAME_STATUS = {
    WAITING: "waiting",
    COUNTDOWN: "countdown",
    QUESTIONS: "questions",
    END: "end",
    ROUND_WAITING: "roundWaiting",
    ROUND_ENDING: "roundEnding",
};

export const GAME_TYPE = {
    VIDEO: "video",
    CATEGORY: "category",
};

export const GAME_MODE = {
    SPRINT: "sprint",
    TRAINING: "training",
    MARATHON: "marathon",
};

export const YT_URL = "https://www.youtube.com/embed/";

export const SUPER_GAME_TEXT = "✨SUPER GAME!✨";
export const REQUEST_NOTIFICATION_PERMISSION = "requestNotificationPermission";
export const CHANGE_NOTIFICATION_PERMISSION = "changeNotificationPermission";
export const NOTIFICATION_PERMISSION = "notificationPermission";
export const SUBSCRIBE_NOTIFICATION = "subscribeNotification";
export const UNSUBSCRIBE_NOTIFICATION = "unsubscribeNotification";
export const NOTIFICATION_ERROR = "notificationError";
export const GET_UI_LABELS = "getUILabels";
export const UPDATE_UI_LABELS = "updateUILabels";

export const STEAL_POINTS = "STEAL_POINTS";
export const SHOW_HINT = "SHOW_HINT";

export const coinTitle = `<img src="/image/mojo-coin.png" title="MojoPoints" class="mojo-coin-title" alt="coin"/>`;
export const coin = `<img src="/image/mojo-coin.png" title="MojoPoints" class="mojo-coin" alt="coin"/>`;

export const stealIcon = `<img src="/image/steal.png" class="powerup-tips">`;
export const skipIcon = `<img src="/image/skip.png" style="height: 13px;">`;
export const hintIcon = `<img src="/image/hint.png" style="height: 13px;">`;
