/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import GeneralButton from "../Button/Button";
import { useSelector } from "react-redux";
import {
    SOCKET_SEND_EVENTS,
    SOCKET_ACTIONS,
    SOCKET_RECEIVE_EVENTS,
} from "../../helpers/constant";
import ClearIcon from "@mui/icons-material/Clear";
import giftCard from "../../pages/EndScreen/gift_card.png";
import EmailIcon from "@mui/icons-material/Email";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import validator from "validator";
import CircularProgress from "@mui/material/CircularProgress";
import useSocketEvents from "../../hooks/useSocketEvents";
import { useNavigate } from "react-router-dom";
import { getLabel } from "../../helpers/Global";

export default function EndScreenButtons({
    openModal,
    handleModalClose,
    winner,
    uiLabels,
}) {
    const theme = useTheme();
    const navigate = useNavigate();

    const socketFromStore = useSelector(({ socket }) => socket.socket);

    const [email, setEmail] = useState(null);
    const [emailError, setEmailError] = useState("");
    const [loading, setLoading] = useState(false);

    function handleEmailChange(e) {
        setEmail(e.target.value);
    }

    function handleGiftCardRequest() {
        if (!validator.isEmail(email)) {
            setEmailError("Enter a valid email!");
        } else {
            setEmailError("");
            setLoading(true);
            socketFromStore.emit(SOCKET_SEND_EVENTS.REDEEM_PRIZE, email);
        }
    }

    function handlePrizeRedeemed() {
        setEmailError("Thank you for playing");
        setLoading(false);
        setTimeout(() => {
            handleModalClose();
        }, 2000);
    }

    function handleRedeemError(error) {
        setEmailError(error);
        setLoading(false);
        setTimeout(() => {
            handleModalClose();
        }, 2000);
    }

    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.REDEEM_REQUESTED,
        null,
        handlePrizeRedeemed
    );

    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.REDEEM_ERROR,
        null,
        handleRedeemError
    );

    return (
        <Modal
            open={openModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    width: 500,
                    maxWidth: "100%",
                    bgcolor: "#181818",
                    border: "1px solid #2B2B2B",
                    borderRadius: "8px",
                    p: 0,
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        borderBottom: "1px solid #2B2B2B",
                        borderRadius: "8px",
                        lineHeight: "63px",
                        height: "64px",
                        px: 3,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        {`Congrats, ${
                            winner && winner.name ? winner.name : ""
                        }`}
                    </Typography>
                    <ClearIcon
                        sx={{ cursor: "pointer" }}
                        id="close_modal_icon"
                        onClick={handleModalClose}
                    ></ClearIcon>
                </Box>
                <Box sx={{ px: 3 }}>
                    <Typography sx={{ mt: 2 }}>
                        {getLabel(
                            uiLabels,
                            "messageRedeemModal1",
                            "Input your email address so we can send you your prize! Don't worry, you won't get any spam from us."
                        )}
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                        {getLabel(
                            uiLabels,
                            "messageRedeemModal2",
                            "If you run into any issues, contact us at triviafeedback@watchmojo.com. We're here to help!"
                        )}
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                        {getLabel(
                            uiLabels,
                            "messageRedeemModal3",
                            "See you next time!"
                        )}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        mt: 1,
                        mx: "auto",
                        justifyContent: "center",
                        display: "flex",
                    }}
                >
                    <img
                        src={giftCard}
                        alt="Gift Card"
                        style={{ maxWidth: "100%" }}
                    />
                </Box>
                <Box sx={{ px: 3 }}>
                    <Typography sx={{ mt: 2, fontSize: "13px" }}>
                        {emailError}
                    </Typography>
                    <TextField
                        id="email"
                        label=""
                        placeholder="Enter your email"
                        type="email"
                        onChange={(e) => handleEmailChange(e)}
                        sx={{
                            backgroundColor: "#F6F6F6",
                            color: "#A5A5A5",
                            fontSize: "14px",
                            width: "100%",
                            borderRadius: "4px",
                            paddingRight: 0,
                        }}
                        InputProps={{
                            style: {
                                paddingRight: "0",
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <GeneralButton
                                        bgcolor="#00B3E2"
                                        color="#00171F"
                                        mRight={"0"}
                                        hoverColor="#00cbff"
                                        handleButtonClick={
                                            handleGiftCardRequest
                                        }
                                        id={"gift_card_request"}
                                        minHeight={"56px"}
                                        maxHeight={"75px"}
                                        textTransform="none"
                                        fontWeight="500"
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <CircularProgress />
                                        ) : (
                                            "Send my giftcard!"
                                        )}
                                    </GeneralButton>
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                    />
                </Box>
                {/* <Box sx={{ px: 3, mt: 2 }}>
                    <Link
                        href="https://www.phaze.io/"
                        variant="body2"
                        underline="always"
                        target="_blank"
                        rel="noopener"
                        sx={{
                            color: "#00B3E2",
                        }}
                    >
                        Phaze.io Terms and Conditions
                    </Link>
                </Box> */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "end",
                        px: 3,
                        py: 2,
                        mt: 2,
                        borderTop: "1px solid #2B2B2B",
                    }}
                >
                    <GeneralButton
                        bgcolor={theme.palette.secondaryButton.main}
                        color={theme.palette.secondaryButton.textColor}
                        hoverColor={theme.palette.hoverColor.main}
                        handleButtonClick={handleModalClose}
                        id={"close_modal_button"}
                        minHeight={"48px"}
                        maxHeight={"75px"}
                        width={"auto"}
                    >
                        Close
                    </GeneralButton>
                </Box>
            </Box>
        </Modal>
    );
}
