import { Box, Button, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    SHOW_HINT,
    SOCKET_SEND_EVENTS,
    STEAL_POINTS,
    coin,
} from "../../helpers/constant";
import cssStyle from "./PowerUp.module.css";
import { getLabel } from "../../helpers/Global";

export default function PowerUp({
    powerUp,
    questionStart,
    clicked,
    spectator,
    points,
    usedPowerUps,
    swapInactive,
    hintInactive,
    theme,
    uiLabels,
}) {
    const socketFromStore = useSelector(({ socket }) => socket.socket);
    const [powerUpClicked, setPowerUpClicked] = useState(false);

    useEffect(() => {
        if (questionStart) {
            setPowerUpClicked(false);
        }
    }, [questionStart]);

    const handleClick = useCallback(() => {
        if (!powerUp) return;

        if (powerUpInactive()) return;

        setPowerUpClicked(true);

        socketFromStore.emit(SOCKET_SEND_EVENTS.USE_POWER_UPS, powerUp.id);
    }, [
        socketFromStore,
        powerUp,
        points,
        questionStart,
        clicked,
        swapInactive,
        hintInactive,
    ]);

    const powerUpInactive = useCallback(() => {
        //if powerup are free, then check if player already used the power ups in previous questions
        if (powerUp.points <= 0 && usedPowerUps.includes(powerUp.id)) {
            return true;
        }

        if (clicked) {
            return true;
        }

        if (!questionStart) {
            return true;
        }

        if (spectator) {
            return true;
        }

        if (powerUpClicked) {
            return true;
        }

        if (powerUp.points > 0 && points < powerUp.points) {
            return true;
        }

        if (powerUp.id == STEAL_POINTS && swapInactive) {
            return true;
        }

        if (hintInactive) {
            return true;
        }

        return false;
    }, [
        powerUp,
        clicked,
        spectator,
        questionStart,
        points,
        swapInactive,
        hintInactive,
    ]);

    const getClass = useCallback(() => {
        if (powerUpInactive()) return cssStyle.inactivePowerUp;

        return "";
    }, [clicked, spectator, questionStart, points, swapInactive, hintInactive]);

    const getPowerUpIcon = useCallback(() => {
        switch (powerUp.id) {
            case "SKIP_QUESTION":
                return "/image/skip.png";

            case "STEAL_POINTS":
                return "/image/steal.png";

            case "SHOW_HINT":
                return "/image/hint.png";
        }
    }, [powerUp]);

    const getPoints = useCallback(() => {
        const filter = usedPowerUps.filter((id) => {
            return id === powerUp.id;
        });

        return powerUp.points * (filter.length + 1);
    }, [usedPowerUps]);

    return (
        <>
            <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <Button
                    className={`btn-powerUp ${getClass()}`}
                    onClick={handleClick}
                    disabled={powerUpInactive()}
                    title={`${getPoints()} MojoPoints`}
                    sx={{
                        "&:disabled": {
                            backgroundColor: theme.palette.wmColor.main,
                            color: "white",
                        },
                        display: "grid",
                        color: "white",
                        lineHeight: 1,
                    }}
                >
                    <Box sx={{ width: { xs: "90px", md: "100px" } }}>
                        <img
                            className={`img-powerUp`}
                            src={getPowerUpIcon()}
                            alt={powerUp.label}
                            style={{ width: "100%" }}
                        />
                    </Box>

                    {getLabel(
                        uiLabels,
                        `btn${powerUp.label.toUpperCase()}`,
                        powerUp.label
                    )}
                </Button>
            </Box>
        </>
    );
}
