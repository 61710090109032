import React, { useRef, useEffect } from "react";
import { Box } from "@mui/material";
import Player from "../Player/Player";
import styles from "./Players.module.css";
import { ViewportList } from "react-viewport-list";

const Players = ({ players = [], questionStart = false, sx }) => {
    const playersContainer = useRef();
    const defaultSX = {
        position: "relative",
        width: { xs: "100%" },
        overflowX: "auto",
    };

    const handleScroll = (evt) => {
        if (!playersContainer) return;

        evt.preventDefault();
        playersContainer.current.scrollLeft += evt.deltaY;
    };

    useEffect(() => {
        if (sx) {
            const keys = Object.keys(sx);
            keys.forEach((key) => {
                defaultSX[key] = sx[key];
            });
        }

        if (!playersContainer || !playersContainer.current) return;

        playersContainer.current.addEventListener("wheel", handleScroll);

        return () => {
            if (!playersContainer || !playersContainer.current) return;

            playersContainer.current.removeEventListener("wheel", handleScroll);
        };
    }, []);

    return (
        <Box
            ref={playersContainer}
            className={styles.playersContainer}
            sx={defaultSX}
            display={"flex"}
        >
            <ViewportList items={players} itemMinSize={10} margin={0} axis="x">
                {(player, index) => {
                    if (player.spectator) {
                        return;
                    }

                    return (
                        <Player
                            key={player.id}
                            player={player}
                            questionStart={questionStart}
                        />
                    );
                }}
            </ViewportList>
        </Box>
    );
};

export default Players;
