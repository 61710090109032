import React, { useCallback, useEffect, useRef, useState } from "react";

import { useLocation } from "react-router-dom";
import { Box, Container, CssBaseline, Grid, Typography } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import SnackBar from "../../helpers/SnackBar";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import { useSelector, useDispatch } from "react-redux";

import CountdownHeader from "../../components/CountdownHeader/CountdownHeader";
import HomeLogin from "../../components/HomeLogin/HomeLogin";
import HomeCountdown from "../../components/HomeCountdown/HomeCountdown";
import HomeBottomTab from "../../components/HomeBottomTab/HomeBottomTab";
import YoutubeVideo from "../../components/YoutubeVideo/YoutubeVideo";

import useMediaQuery from "@mui/material/useMediaQuery";
import useSocket from "../../hooks/useSocket";
import useSocketEvents from "../../hooks/useSocketEvents";

//bg images
import circleBG from "./public-trivia-bg-01_255x252.png";
import squareBG from "./public-trivia-bg-02_322x306.png";

import cssStyle from "./Home.module.css";

import { addToken } from "../../reducers/TokenReducer";

import {
    CHANGE_NOTIFICATION_PERMISSION,
    GET_UI_LABELS,
    NOTIFICATION_ERROR,
    NOTIFICATION_PERMISSION,
    REQUEST_NOTIFICATION_PERMISSION,
    SOCKET_ACTIONS,
    SOCKET_RECEIVE_EVENTS,
    SUPER_GAME_TEXT,
    coin,
} from "../../helpers/constant";
import { getLabel, joinQueue, toggleMojoPoints } from "../../helpers/Global";
import AlertDialog from "../../components/AlertDialog/AlertDialog";
import { addAlert, removeAlert } from "../../reducers/AlertReducer";
import HomeCategoryPrize from "../../components/HomeCategoryPrize/HomeCategoryPrize";
import HowToPlay from "../../components/HowToPlay/HowToPlay";
import WhatAreMojoPoints from "../../components/WhatAreMojoPoints/WhatAreMojoPoints";
import AlertCookie from "../../components/AlertCookie/AlertCookie";
import HomeHeader from "../../components/HomeHeader/HomeHeader";

import {
    updateLanguage,
    updateNotification,
    updateUILabels,
} from "../../reducers/GameReducer";

import CashBattleInfo from "../../components/CashBattleInfo/CashBattleInfo";
import ActiveGames from "../../components/ActiveGames/ActiveGames";
import HomeWeeklyLeaderboard from "../../components/HomeWeeklyLeaderboard/HomeWeeklyLeaderboard";

export default function Home({ uiLabels }) {
    const theme = useTheme();
    const dispatch = useDispatch();

    const search = useLocation().search;
    let token = useSelector(({ token }) => token.token);
    const [joinCode, setJoinCode] = useState(null);
    const [declinedPoints, setDeclinedPoints] = useState(false);
    const [howToPlay, setHowToPlay] = useState(false);
    const [whatAreMojoPoints, setWhatAreMojoPoints] = useState(false);
    //list of labels to translate
    const [labels, setLabels] = useState(null);

    const mainContainer = useRef();

    const error = useSelector(({ message }) => message.error);
    const message = useSelector(({ message }) => message.message);
    const openSnackbar = useSelector(({ message }) => message.openSnackbar);
    const player = useSelector(({ player }) => player);

    const gameStatus = useSelector(({ game }) => game.gameStatus);
    const serverStatus = useSelector(({ game }) => game.serverStatus);
    const questionIndex = useSelector(({ game }) => game.questionIndex);
    const triviaLivestreams = useSelector(({ game }) => game.triviaLivestreams);

    const currentTitle = useSelector(({ settings }) => settings.currentTitle);
    const gameThumbnail = useSelector(({ game }) => game.gameThumbnail);
    const superGame = useSelector(({ game }) => game.superGame);
    const language = useSelector(({ game }) => game.language);
    const requiredPoints = useSelector(({ game }) => game.requiredPoints);

    const socketFromStore = useSelector(({ socket }) => socket.socket);

    const multiplayerCategory = useSelector(
        ({ game }) => game.multiplayerCategory
    );
    const multiplayerVideo = useSelector(({ game }) => game.multiplayerVideo);

    const handleJoinQueue = useCallback(
        (payWithPoints) => {
            setDeclinedPoints(false);
            joinQueue(socketFromStore, joinCode, payWithPoints);
        },
        [socketFromStore, joinCode]
    );

    const handleHowToPlay = () => {
        setHowToPlay(true);
    };

    const handleMojoPoints = () => {
        setWhatAreMojoPoints(true);
    };

    //add event for requiring points to play
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.REQUIRE_POINTS,
        null,
        ({ message, requiredPoints }) => {
            dispatch(
                addAlert({
                    title: `Trivia ${getLabel(
                        uiLabels,
                        "labelCashBattle",
                        "Cash Battle"
                    )} ${`<img src="/image/power-hour.png" class="cash-battle-icon" alt="Cash Battler" />`}`,
                    message: getLabel(
                        uiLabels,
                        "messageRequiredPoints",
                        message
                    ).replace(/#0/g, requiredPoints),
                    buttons: [
                        {
                            text: getLabel(uiLabels, "btnCancel", "Cancel"),
                            callback: () => {
                                dispatch(removeAlert());
                                setDeclinedPoints(true);
                            },
                        },
                        {
                            text: getLabel(
                                uiLabels,
                                "labelHowToPlay",
                                "How to Play"
                            ),
                            callback: () => {
                                setHowToPlay(true);
                            },
                        },
                        {
                            text: getLabel(
                                uiLabels,
                                "whatAreMojoPointsTitle",
                                "What are MojoPoints?"
                            ).replace(/#0/g, `${coin}&nbsp;`),
                            callback: () => {
                                setWhatAreMojoPoints(true);
                            },
                        },
                        {
                            text: getLabel(
                                uiLabels,
                                "btnRedeemPoints",
                                "Redeem Points"
                            ),
                            callback: () => {
                                handleJoinQueue(true);
                                dispatch(removeAlert());
                            },
                        },
                    ],
                })
            );
        }
    );

    //add event for insufficient points to play
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.INSUFFICIENT_POINTS,
        null,
        ({ message, requiredPoints, points }) => {
            message = message.replace(/#0/g, requiredPoints);
            message = message.replace(/#1/g, points);
            dispatch(
                addAlert({
                    message: message,
                    buttons: [
                        {
                            text: getLabel(
                                uiLabels,
                                "whatAreMojoPointsTitle",
                                "What are MojoPoints?"
                            ).replace(/#0/g, `${coin}&nbsp;`),
                            callback: () => {
                                setWhatAreMojoPoints(true);
                            },
                        },
                        {
                            text: getLabel(uiLabels, "btnOk", "Ok"),
                            callback: () => {
                                dispatch(removeAlert());
                                setDeclinedPoints(true);
                            },
                        },
                    ],
                })
            );
        }
    );

    //MESSAGE FUNCTIONS

    const handleMessage = (event) => {
        const payload = event.data;

        if (payload.action) {
            switch (payload.action) {
                case CHANGE_NOTIFICATION_PERMISSION:
                    dispatch(updateNotification(payload.permission));
                    break;

                case NOTIFICATION_PERMISSION:
                    dispatch(updateNotification(payload.permission));

                    if (
                        payload.permission === "default" &&
                        payload.hasOwnProperty("prompt") &&
                        payload.prompt == 0
                    ) {
                        dispatch(
                            addAlert({
                                message: getLabel(
                                    uiLabels,
                                    "messageNotifyMe",
                                    `Notify me when a ${SUPER_GAME_TEXT} starts?`
                                ),
                                buttons: [
                                    {
                                        text: "Yes",
                                        callback: () => {
                                            dispatch(removeAlert());
                                            let message = {
                                                action: REQUEST_NOTIFICATION_PERMISSION,
                                            };
                                            //method to send message to parent outside iframe
                                            window.top.postMessage(
                                                message,
                                                "*"
                                            );
                                        },
                                    },
                                    {
                                        text: "No",
                                        callback: () => {
                                            dispatch(removeAlert());
                                        },
                                    },
                                ],
                            })
                        );
                    }
                    break;

                case NOTIFICATION_ERROR:
                    dispatch(
                        addAlert({
                            message: payload.message,
                            buttons: [
                                {
                                    text: "Ok",
                                    callback: () => {
                                        dispatch(removeAlert());
                                    },
                                },
                            ],
                        })
                    );
                    break;

                case GET_UI_LABELS:
                    const keys = Object.keys(payload.uiLabels ?? []);
                    setLabels(keys);
                    dispatch(updateUILabels(payload.uiLabels));
                    break;
            }
        }
    };

    useEffect(() => {
        toggleMojoPoints(false);

        window.addEventListener("message", handleMessage);

        let message = {
            action: NOTIFICATION_PERMISSION,
        };
        //method to send message to parent outside iframe
        window.top.postMessage(message, "*");

        message = {
            action: GET_UI_LABELS,
        };
        //method to send message to parent outside iframe
        window.top.postMessage(message, "*");

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);
    //MESSAGE FUNCTIONS

    // get all the player details when lobby page is opened
    useEffect(() => {
        if (!search || search === "") {
            return;
        }

        const searchParams = new URLSearchParams(search);
        const token = searchParams.get("token");
        const code = searchParams.get("code");
        const language_code = searchParams.get("language_code");

        if (token !== null) {
            dispatch(addToken(token));
        }
        setJoinCode(code ?? "");
        dispatch(updateLanguage(language_code ?? "en"));
    }, [dispatch, search]);

    useEffect(() => {
        //don't connect to socket until server is ready
        if (!serverStatus) {
            return;
        }

        if (!socketFromStore) {
            return;
        }

        handleJoinQueue(false);
    }, [socketFromStore, joinCode, serverStatus]);

    //establish socket connection
    useSocket(token, language, labels);

    //method to create countdown section
    function countdown() {
        return (
            <>
                <Grid
                    item
                    xs={12}
                    md={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box
                        sx={{
                            px: theme.space.home.px,
                        }}
                    >
                        <Box>
                            <HomeCategoryPrize
                                subCategory={currentTitle}
                                gameThumbnail={gameThumbnail}
                                uiLabels={uiLabels}
                                theme={theme}
                                requiredPoints={requiredPoints}
                            />
                        </Box>
                        <CountdownHeader
                            gameStatus={gameStatus}
                            player={player}
                            questionIndex={questionIndex}
                            uiLabels={uiLabels}
                        />
                        <HomeCountdown
                            gameStatus={gameStatus}
                            uiLabels={uiLabels}
                        />
                    </Box>
                </Grid>
            </>
        );
    }

    //method to create login section or player info (player already logged in)
    function loginPlayer() {
        return (
            <Grid item xs={12} md={12} sx={{ mt: 3, px: theme.space.home.px }}>
                <HomeLogin
                    player={player}
                    declinedPoints={declinedPoints}
                    handleJoinQueue={handleJoinQueue}
                    handleHowToPlay={handleHowToPlay}
                    handleMojoPoints={handleMojoPoints}
                    superGame={superGame}
                    uiLabels={uiLabels}
                />
            </Grid>
        );
    }

    return (
        /* if no error, lobby page, else errorPage */
        error === "" ? (
            <>
                <CssBaseline />
                <Container
                    sx={{
                        position: "relative",
                        padding: "0px !important",
                        width: "100% !important",
                        maxWidth: {
                            xs: "100% !important",
                            md: "100% !important",
                        },
                        height: "auto",
                    }}
                    ref={mainContainer}
                >
                    <img
                        src={circleBG}
                        className={cssStyle.circleBG}
                        alt="bg1"
                    />
                    <img
                        src={squareBG}
                        className={cssStyle.squareBG}
                        alt="bg2"
                    />
                    <Box sx={{ px: theme.space.home.px }}>
                        {player &&
                            player.playerId &&
                            !player.hasConsent &&
                            !isNaN(player.playerId) && (
                                <AlertCookie uiLabels={uiLabels} />
                            )}
                    </Box>
                    <Grid
                        container
                        spacing={0}
                        sx={{
                            width: "100%",
                            position: "relative",
                        }}
                    >
                        <Grid item xs={12} sx={{ px: theme.space.home.px }}>
                            <HomeHeader
                                uiLabels={uiLabels}
                                requiredPoints={requiredPoints}
                                theme={theme}
                            />
                        </Grid>

                        {/* show countdown section */}
                        {countdown()}

                        {/* show player info */}
                        {loginPlayer()}

                        <Grid item xs={12}>
                            <Box sx={{ p: 3 }}>
                                <CashBattleInfo uiLabels={uiLabels} />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <HomeBottomTab
                                player={player}
                                uiLabels={uiLabels}
                                theme={theme}
                            />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sx={{
                                mt: 3,
                                px: theme.space.home.px,
                            }}
                        >
                            <ActiveGames
                                uiLabels={uiLabels}
                                header={getLabel(
                                    uiLabels,
                                    "labelCategoryGames",
                                    "Category Games"
                                )}
                                games={multiplayerCategory}
                                theme={theme}
                            />
                            <ActiveGames
                                uiLabels={uiLabels}
                                header={getLabel(
                                    uiLabels,
                                    "labelJoinAGame",
                                    "Join a Game!"
                                )}
                                games={multiplayerVideo}
                                gridSize={{ xs: 12, md: 4 }}
                                theme={theme}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mt: 3,
                                px: theme.space.home.px,
                            }}
                        >
                            <Typography
                                variant="h5"
                                sx={{
                                    mb: 1,
                                    letterSpacing: 2,
                                    fontFamily: theme.font.family,
                                }}
                            >
                                {getLabel(
                                    uiLabels,
                                    "labelTopPlayers",
                                    "Top 10 Players"
                                )}
                            </Typography>
                            <HomeWeeklyLeaderboard
                                uiLabels={uiLabels}
                                player={player}
                            />
                        </Grid>
                        <Grid
                            className="container-livestream"
                            item
                            xs={12}
                            sx={{
                                mt: 3,
                                px: theme.space.home.px,
                            }}
                        >
                            <YoutubeVideo
                                triviaLivestreams={triviaLivestreams}
                                uiLabels={uiLabels}
                            />
                        </Grid>
                    </Grid>
                    {message !== "" && (
                        <SnackBar open={openSnackbar} message={message} />
                    )}
                    <AlertDialog />
                    <HowToPlay
                        show={howToPlay}
                        handleClose={() => {
                            setHowToPlay(false);
                        }}
                        uiLabels={uiLabels}
                    />
                    <WhatAreMojoPoints
                        show={whatAreMojoPoints}
                        handleClose={() => {
                            setWhatAreMojoPoints(false);
                        }}
                        uiLabels={uiLabels}
                    />
                </Container>
            </>
        ) : (
            <ErrorPage />
        )
    );
}
