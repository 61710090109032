/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Avatar,
    Badge,
    Button,
    Grid,
    ListItem,
    Tooltip,
    Typography,
    Box,
} from "@mui/material";

import { COLORS } from "../../helpers/constant";

import playerCSS from "./Player.module.css";

// import icons
import CheckIcon from "@mui/icons-material/Check";

import { AVATAR_SIZE, GAME_STATUS } from "../../helpers/constant";
import AlertDialog from "../AlertDialog/AlertDialog";

import crown from "./trivia-crown-icon-2.png";
import Ribbon from "../Ribbon/Ribbon";
import { updatePlayerScore } from "../../reducers/PlayersReducers";

import CountUp from "react-countup";

export default function Player({
    player,
    minWidth,
    questionStart,
    forceActive,
    showScore,
    sx,
}) {
    const dispatch = useDispatch();

    const [score, setScore] = useState(0);

    const gameStatus = useSelector(({ game }) => game.gameStatus);

    const me = useSelector(({ player }) => player);

    const playerAnswerStatus = useSelector(
        ({ game }) => game.playerAnswerStatus[player.id]
    );

    const playerScores = useSelector(({ players }) => players.playerScores);

    let [showTooltip, setShowTooltip] = useState(false);

    function isLobbyRoom() {
        return gameStatus === GAME_STATUS.WAITING;
    }

    function hasCrown() {
        if (!player.score || player.sore === 0) return false;

        if (
            [
                GAME_STATUS.ROUND_WAITING,
                GAME_STATUS.ROUND_ENDING,
                GAME_STATUS.END,
            ].includes(gameStatus)
        )
            return false;

        return player.isLeader && !isLobbyRoom();
    }

    function isAnswerCorrect() {
        if (
            [
                GAME_STATUS.ROUND_WAITING,
                GAME_STATUS.ROUND_ENDING,
                GAME_STATUS.END,
            ].includes(gameStatus)
        ) {
            return false;
        }

        return player.isCorrect && !isLobbyRoom();
    }

    function isPlayerMe() {
        return player.id == me.playerId;
    }

    const [active, setActive] = useState(playerCSS.inactive);

    function handleShowTooltip() {
        setShowTooltip(true);
    }

    function handlehideTooltip() {
        setShowTooltip(false);
    }

    useEffect(() => {
        if (forceActive) {
            setActive(playerCSS.active);
            return;
        }

        if (gameStatus === GAME_STATUS.ROUND_ENDING) {
            setActive(playerCSS.active);
            return;
        }

        if (
            [GAME_STATUS.COUNTDOWN, GAME_STATUS.ROUND_ENDING].includes(
                gameStatus
            ) ||
            isAnswerCorrect() ||
            player.didAnswer
        ) {
            setActive(playerCSS.active);
            return;
        }

        if (!playerAnswerStatus) {
            setActive(playerCSS.inactive);
            return;
        }

        if (playerAnswerStatus) {
            setActive(playerCSS.active);
            return;
        }
    }, [
        gameStatus,
        playerAnswerStatus,
        player.isCorrect,
        player.didAnswer,
        forceActive,
    ]);

    useEffect(() => {
        if (questionStart === true) {
            dispatch(updatePlayerScore(player.id, player.score));
        }
    }, [questionStart]);

    const getLastScore = useCallback(() => {
        if (!playerScores) {
            return 0;
        }

        if (!playerScores[player.id]) {
            return 0;
        }

        return playerScores[player.id];
    }, [playerScores, player]);

    const avatarMaxWidth = () => {
        if (sx && sx.avatar) {
            return sx.avatar.maxWidth;
        }

        return { xs: "fit-content", md: "100px" };
    };

    const avatarSize = () => {
        if (sx && sx.avatar) {
            return sx.avatar.size;
        }

        return {
            xs: isPlayerMe() ? AVATAR_SIZE.me.xs : AVATAR_SIZE.other.xs,
            sm: isPlayerMe() ? AVATAR_SIZE.me.sm : AVATAR_SIZE.other.sm,
        };
    };

    const avatarFontSize = () => {
        if (sx && sx.avatar) {
            return sx.avatar.fontSize;
        }

        return { xs: "0.7rem", sm: "0.85rem" };
    };

    return (
        <>
            <ListItem
                className={`${playerCSS.listItemDefault} ${active}`}
                sx={{
                    minWidth: minWidth ?? {
                        xs: "auto",
                        md: "100px",
                        lg: "120px",
                    },
                    maxWidth: avatarMaxWidth(),
                    alignItems: "end",
                    padding: {
                        xs: "2px!important",
                        sm: "10px",
                    },
                }}
            >
                <Grid
                    container
                    sx={{ width: "100%", flexDirection: "column" }}
                    alignItems="center"
                >
                    <Grid
                        item
                        xs={12}
                        align="center"
                        sx={{
                            paddingTop: {
                                xs: hasCrown() ? "8px" : 0,
                                md: "0px",
                            },
                        }}
                    >
                        <Badge
                            overlap="circular"
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            sx={{
                                width: "auto",
                                mt: { md: hasCrown() ? 2 : 0 },
                                position: "relative",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Button
                                    variant="text"
                                    onClick={handleShowTooltip}
                                    onMouseEnter={handleShowTooltip}
                                    onMouseLeave={handlehideTooltip}
                                    sx={{
                                        padding: "0px",
                                        backgroundColor:
                                            "transparent!important",
                                        minWidth: "auto",
                                    }}
                                >
                                    <Tooltip
                                        onClose={handlehideTooltip}
                                        open={showTooltip}
                                        disableFocusListener
                                        title={player.name}
                                    >
                                        <Avatar
                                            src={player.avatar}
                                            sx={{
                                                mx: "auto",
                                                padding: "0%",
                                                border:
                                                    player.name === "open"
                                                        ? {
                                                              xs: "2px dashed #fff",
                                                              sm: "2px dashed #fff",
                                                          }
                                                        : { xs: 2, md: 4 },
                                                borderColor: isPlayerMe()
                                                    ? `${COLORS.wmTheme}!important`
                                                    : player.name === "open"
                                                    ? "#fff!important"
                                                    : "transparent!important",
                                                opacity:
                                                    player.name === "open" ||
                                                    isAnswerCorrect()
                                                        ? "0.6"
                                                        : "1",
                                                width: avatarSize(),
                                                height: avatarSize(),
                                                backgroundColor:
                                                    player.name === "open"
                                                        ? "#8B8B8B"
                                                        : player.bgColor,
                                                "> img": {
                                                    borderRadius: "50%",
                                                },
                                            }}
                                        >
                                            {" "}
                                        </Avatar>
                                    </Tooltip>
                                </Button>
                            </Box>

                            <img
                                src={crown}
                                className={
                                    hasCrown()
                                        ? playerCSS.hasCrown
                                        : playerCSS.noCrown
                                }
                                alt="bg1"
                            />

                            {(gameStatus === GAME_STATUS.QUESTIONS ||
                                showScore) && (
                                <Typography
                                    className={playerCSS.absoluteCenter}
                                    noWrap
                                    variant="body1"
                                    sx={{
                                        letterSpacing: "-0.3px",
                                        fontSize: {
                                            xs: "0.75rem",
                                            sm: "0.9rem",
                                        },
                                    }}
                                >
                                    <CountUp
                                        start={getLastScore()}
                                        end={player.score}
                                        duration={0.75}
                                    />
                                </Typography>
                            )}

                            <CheckIcon
                                className={playerCSS.playerCorrect}
                                sx={{
                                    display: isAnswerCorrect()
                                        ? "block"
                                        : "none",
                                    fontSize: {
                                        xs: "30px",
                                        sm: "50px",
                                    },
                                    fontWeight: "600",
                                }}
                            />

                            <Ribbon
                                bonusPoints={player.bonusPoints ?? []}
                                showRibbon={player.showRibbon}
                            />
                        </Badge>
                    </Grid>

                    <Grid item xs={12} align="center">
                        {/* gameStatus = waiting, show player name
                        gameStatus = in-game, show score */}

                        <Typography
                            noWrap
                            component="h6"
                            sx={{
                                pt: 1,
                                fontSize: avatarFontSize(),
                                textAlign: { xs: "center" },
                                opacity: player.name === "open" && 0.6,
                                maxWidth:
                                    sx && sx.avatar && sx.avatar.maxWidth
                                        ? sx.avatar.maxWidth
                                        : { xs: "95%", md: "100%" },
                            }}
                        >
                            {player.name}
                        </Typography>
                    </Grid>
                </Grid>
            </ListItem>
            <AlertDialog />
        </>
    );
}
