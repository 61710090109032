import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { GAME_STATUS } from "../../helpers/constant";
import ButtonJoin from "../Button/ButtonJoin";
import { getLabel } from "../../helpers/Global";

export default function CountdownHeader({
    gameStatus = GAME_STATUS.WAITING,
    player = null,
    textHeader = null,
    css,
    questionIndex,
    uiLabels,
}) {
    function showHeader() {
        let header = (
            <Box>
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: { xs: "20px", md: "20px" },
                        fontWeight: 700,
                        width: "100%",
                        textAlign: !css
                            ? { xs: "center", md: "center" }
                            : css.textAlign,
                    }}
                >
                    {textHeader === null
                        ? `${getLabel(
                              uiLabels,
                              "labelGameStartsIn",
                              "Game Starts In"
                          )}:`
                        : textHeader}
                </Typography>
            </Box>
        );

        if (textHeader !== null) return header;

        // waiting, started, full,
        switch (gameStatus) {
            case GAME_STATUS.COUNTDOWN:
                header = (
                    <Box>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: { xs: "20px", md: "20px" },
                                fontWeight: 700,
                                width: "100%",
                                textAlign: { xs: "center", md: "center" },
                            }}
                        >
                            {`${getLabel(
                                uiLabels,
                                "labelGameStarted",
                                "Game Started"
                            )}!`}
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                color: "rgb(0, 180, 00)",
                                fontSize: "30px",
                                fontWeight: 900,
                                width: "100%",
                                textAlign: { xs: "center", md: "center" },
                                lineHeight: 1,
                            }}
                        >
                            {`${getLabel(
                                uiLabels,
                                "labelLetsPlay",
                                "Let's Play"
                            )}!`}
                        </Typography>
                        <Box
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            sx={{
                                width: "100%",
                                mt: 1,
                            }}
                        >
                            <ButtonJoin
                                player={player}
                                gameStatus={gameStatus}
                                uiLabels={uiLabels}
                                label={getLabel(uiLabels, "btnPlay", "Play")}
                            />
                        </Box>
                    </Box>
                );
                break;

            case GAME_STATUS.QUESTIONS:
                header = (
                    <Box>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: { xs: "20px", md: "20px" },
                                fontWeight: 700,
                                width: "100%",
                                textAlign: { xs: "center", md: "center" },
                            }}
                        >
                            {`${getLabel(
                                uiLabels,
                                "labelGameIsJoinCatchUp",
                                `The game is already ${questionIndex} question
                            ${
                                questionIndex > 1 ? "s" : ""
                            } in. Join the game and catch up!`
                            ).replace(/2/g, questionIndex)}`}
                        </Typography>
                        <Box
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            sx={{
                                width: "100%",
                            }}
                        >
                            <ButtonJoin
                                player={player}
                                gameStatus={gameStatus}
                                uiLabels={uiLabels}
                                label={
                                    !player.playerJoin
                                        ? getLabel(
                                              uiLabels,
                                              "btnJoinCatchUp",
                                              "Join Game & Catch Up!"
                                          )
                                        : getLabel(
                                              uiLabels,
                                              "btnBackToGame",
                                              "Back to game"
                                          )
                                }
                            />
                        </Box>
                    </Box>
                );
                break;

            default:
                if (player.playerId && player.playerQueue === null) {
                    header = (
                        <Box>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: { xs: "20px", md: "20px" },
                                    fontWeight: 700,
                                    width: "100%",
                                    textAlign: { xs: "center", md: "center" },
                                }}
                            >
                                {`${getLabel(
                                    uiLabels,
                                    "labelNoSpotsAvailable",
                                    "Sorry, no spots available"
                                )}!`}
                            </Typography>
                        </Box>
                    );
                }

                break;
        }
        return header;
    }
    return <>{showHeader()}</>;
}
