import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import {
    Avatar,
    Badge,
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    IconButton,
    Grid,
    TextField,
    Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import CheckIcon from "@mui/icons-material/Check";

import cssStyle from "./DialogGuest.module.css";
import {
    getGuestData,
    getLabel,
    refreshPage,
    saveGuestData,
} from "../../helpers/Global";
import { SOCKET_SEND_EVENTS } from "../../helpers/constant";

export default function DialogGuest({ open, closeDialog, uiLabels }) {
    const UPDATE_USERNAME = "updateUsername";
    const theme = useTheme();
    const socketFromStore = useSelector(({ socket }) => socket.socket);
    let token = useSelector(({ token }) => token.token);
    const player = useSelector(({ player }) => player);
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const sx: SxProps = {
        "& .MuiDialog-container": {
            height: "auto !important",
            width: "100%",
            position: "relative",
            top: 0,
        },
        "& .MuiPaper-root": {
            minWidth: { xs: "90%", md: "400px" },
            backgroundColor: `${theme.home.login.bgColor.dialog}`,
            color: "#FFF",
        },
    };

    const playerNameSx: SxProps = {
        maxWidth: "200px",
        "& .MuiInputLabel-outlined": {
            color: "lightgray",
        },
        "& .MuiInputLabel-shrink": {
            color: "#FFF !important",
            fontSize: "1rem",
        },
        "& .MuiInputBase-input": {
            color: "white",
            height: { xs: "15px", sm: "15px" },
            fontSize: { xs: "1.1rem", sm: "1rem" },
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: theme.palette.wmColor.main,
            },
            "&:hover fieldset": {
                borderColor: theme.palette.wmColor.main,
            },
            "&.Mui-focused fieldset": {
                borderColor: theme.palette.wmColor.main,
            },
        },
    };

    const [avatars, setAvatars] = useState([1, 2]);

    const [selectedAvatar, setSelectedAvatar] = useState(null);
    const textPlayerName = useRef();
    const [isPlayerNameEmpty, setIsPlayerNameEmpty] = useState(false);
    const [buttonName, setButtonName] = useState(
        getLabel(uiLabels, "btnJoinGame", "Join Game")
    );
    const [errorMessage, setErrorMessage] = useState("");

    const loadPlayerData = () => {
        let guestPlayer = getGuestData();

        if (!guestPlayer && player && player.playerId) {
            guestPlayer = {
                playerId: player.playerId,
                playerName: player.playerName,
                playerAvatar: encodeURI(player.playerAvatar),
                isGuest: false,
            };
        }

        return guestPlayer;
    };

    useEffect(async () => {
        try {
            let url = `${process.env.REACT_APP_SOCKET_URL}/avatars`;

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const response = await axios.get(url, config);

            if (response.status === 200 && response.data) {
                const tempAvatars = response.data;
                let index = 0;

                const guestPlayer = loadPlayerData();
                if (guestPlayer) {
                    textPlayerName.current.value = guestPlayer.playerName;

                    //set default values
                    index = tempAvatars.findIndex(
                        (avatar) => avatar.avatar == guestPlayer.ogPlayerAvatar
                    );

                    if (index < 0) {
                        tempAvatars.unshift({
                            avatar: guestPlayer.ogPlayerAvatar,
                            bgColor: null,
                        });
                    }
                    setSelectedAvatar(guestPlayer.playerAvatar);
                    setButtonName(getLabel(uiLabels, "btnUpdate", "Update"));
                } else {
                    setSelectedAvatar(
                        tempAvatars[
                            Math.round(Math.random() * (tempAvatars.length - 1))
                        ].avatar
                    );
                    setButtonName(
                        getLabel(uiLabels, "btnJoinGame", "Join Game")
                    );
                }

                setAvatars(tempAvatars);
            }
        } catch (e) {
            console.log(e);
        }
    }, [token, uiLabels]);

    const handleClose = () => {
        if (closeDialog !== null) {
            closeDialog();
        }
    };

    const handleSelectAvatar = (avatar) => {
        setSelectedAvatar(avatar);
    };

    const handleUpdatePlayerdata = (playerData, playerName, avatar) => {
        playerData.playerName = playerName;
        playerData.playerAvatar = avatar ?? selectedAvatar;

        socketFromStore.emit(SOCKET_SEND_EVENTS.UPDATE_AVATAR, playerData);

        saveGuestData(
            JSON.stringify({
                playerId: playerData.playerId,
                playerName: playerName,
                playerAvatar: playerData.playerAvatar,
                ogPlayerAvatar: playerData.ogPlayerAvatar
                    ? playerData.ogPlayerAvatar
                    : playerData.playerAvatar,
            })
        );

        closeDialog();
    };

    const handleMessage = (event) => {
        const payload = event.data;

        if (payload.action) {
            switch (payload.action) {
                case UPDATE_USERNAME:
                    if (payload.error) {
                        setErrorMessage(payload.error.message);
                        return;
                    }

                    const playerData = loadPlayerData();

                    handleUpdatePlayerdata(
                        playerData,
                        payload.playerName,
                        payload.playerAvatar
                    );
                    break;
            }
        }
    };

    useEffect(() => {
        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    const handleSaveGuest = () => {
        if (textPlayerName.current.value === "") {
            setIsPlayerNameEmpty(true);
            return;
        }

        setIsPlayerNameEmpty(false);
        const playerName = textPlayerName.current.value;

        const playerData = loadPlayerData();

        if (!playerData) {
            saveGuestData(
                JSON.stringify({
                    playerId: uuidv4(),
                    playerName: playerName,
                    playerAvatar: selectedAvatar,
                    ogPlayerAvatar: selectedAvatar,
                })
            );

            closeDialog();
            refreshPage();
        } else {
            //if player is saved in db, then also update username in db
            if (!isNaN(playerData.playerId)) {
                let message = {
                    action: UPDATE_USERNAME,
                    data: {
                        playerId: playerData.playerId,
                        playerName: playerName,
                        playerAvatar: selectedAvatar,
                    },
                };
                //method to send message to parent outside iframe
                window.top.postMessage(message, "*");
                return;
            }

            handleUpdatePlayerdata(playerData, playerName);
        }
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            scroll="paper"
            sx={sx}
        >
            <DialogTitle>
                <Box>
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 700,
                            mb: 1,
                        }}
                    >
                        {getLabel(
                            uiLabels,
                            "labelPersonalInfo",
                            "Personal Info"
                        )}
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ mx: { xs: 0, md: 5 } }}>
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <Avatar
                            src={selectedAvatar}
                            sx={{
                                border: `4px solid ${theme.palette.wmColor.main}`,
                                padding: "0px",
                                backgroundColor: "#8B8B8B",
                                width: { xs: 100, md: 125 },
                                height: { xs: 100, md: 125 },
                            }}
                        >
                            {" "}
                        </Avatar>
                    </Box>

                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            error={isPlayerNameEmpty}
                            className="textFIELD"
                            autoFocus
                            margin="dense"
                            id="playerId"
                            label={getLabel(
                                uiLabels,
                                "labelNickname",
                                "Nickname"
                            )}
                            fullWidth
                            variant="outlined"
                            sx={playerNameSx}
                            inputRef={textPlayerName}
                            size="small"
                            focused
                            autoComplete="off"
                        />
                    </Box>
                    <Typography
                        variant="body1"
                        sx={{
                            mb: 0,
                            mt: 0,
                            textAlign: "center",
                            color: "red",
                            fontSize: "0.9rem",
                        }}
                    >
                        {errorMessage}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, mt: 2 }}>
                        {getLabel(
                            uiLabels,
                            "labelSelectYourAvatar",
                            "Select your avatar"
                        )}
                    </Typography>
                    <Grid container spacing={2}>
                        {avatars.map((avatar, i) => {
                            return (
                                <Grid item xs={3} sm={2} key={i}>
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }}
                                        sx={{
                                            width: "auto",
                                            position: "relative",
                                        }}
                                        badgeContent={
                                            selectedAvatar == avatar.avatar ? (
                                                <IconButton
                                                    disabled
                                                    className={
                                                        cssStyle.avatarSelect
                                                    }
                                                >
                                                    <CheckIcon
                                                        className={
                                                            cssStyle.checkIcon
                                                        }
                                                    ></CheckIcon>
                                                </IconButton>
                                            ) : (
                                                ""
                                            )
                                        }
                                    >
                                        <Avatar
                                            onClick={() => {
                                                handleSelectAvatar(
                                                    avatar.avatar
                                                );
                                            }}
                                            src={avatar.avatar}
                                            sx={{
                                                border:
                                                    selectedAvatar ==
                                                    avatar.avatar
                                                        ? `2px solid ${theme.palette.wmColor.main}`
                                                        : "",
                                                padding: avatar.bgColor
                                                    ? "5px"
                                                    : "0px",
                                                backgroundColor: avatar.bgColor
                                                    ? avatar.bgColor
                                                    : null,
                                                width: { xs: 50, md: 75 },
                                                height: { xs: 50, md: 75 },
                                                cursor: "pointer",
                                            }}
                                        >
                                            {" "}
                                        </Avatar>
                                    </Badge>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    sx={theme.home.login.guestButton}
                    onClick={handleSaveGuest}
                >
                    {buttonName}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
