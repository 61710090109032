import { Button } from "@mui/material";
import React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import { getLabel, isDev } from "../../helpers/Global";
import { useSelector } from "react-redux";
import { SOCKET_SEND_EVENTS } from "../../helpers/constant";

export default function ButtonDeleteGame({ uiLabels }) {
    const socketFromStore = useSelector(({ socket }) => socket.socket);

    const handleOnDelete = () => {
        console.log("Deleting game...");
        socketFromStore.emit(SOCKET_SEND_EVENTS.DELETE_GAME);
    };
    return (
        <>
            {isDev() && (
                <Button
                    variant="contained"
                    startIcon={<DeleteIcon />}
                    onClick={handleOnDelete}
                >
                    {getLabel(
                        uiLabels,
                        "btnDeleteStart",
                        "Delete Game and start again"
                    )}
                </Button>
            )}
        </>
    );
}
