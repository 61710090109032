import React, { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import Timer from "../../components/Timer/Timer";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import styles from "./Question.module.css";
import { useSelector } from "react-redux";
import { isDev } from "../../helpers/Global";
import { SOCKET_SEND_EVENTS } from "../../helpers/constant";

export default function Question({
    theme,
    questionStart,
    questionObj,
    questionIndex,
    questionsCount,
    desktopQuestionCount,
    desktopQuestionFontSize,
    thumbnailHeight,
    socketFromStore,
    playSound,
    handlePlaySound,
}) {
    let [pauseGame, setPauseGame] = useState(false);

    const handlePauseGame = () => {
        socketFromStore.emit(SOCKET_SEND_EVENTS.PAUSE_GAME, !pauseGame);
        setPauseGame((prev) => {
            const newVal = !prev;
            return newVal;
        });
    };

    return (
        <>
            <Box
                sx={{
                    margin: "5px 0px 15px",
                    height: "auto",
                    position: "relative",
                    border: {
                        xs: `1px solid ${theme.palette.wmColor.main}`,
                        md: `1px solid ${theme.palette.wmColor.main}`,
                    },
                    borderRadius: "5px",
                }}
            >
                <Box
                    sx={{
                        px: 0,
                        py: 0,
                        position: "relative",
                        overflow: "hidden",
                        maxHeight: "300px",
                        height: thumbnailHeight,
                    }}
                >
                    <img
                        src={`${questionObj.thumbnail}`}
                        srcSet={`${questionObj.thumbnail}`}
                        alt={questionObj.thumbnail}
                        loading="lazy"
                        style={{
                            width: "100%",
                            objectFit: "cover",
                            maxHeight: "300px",
                            height: thumbnailHeight,
                            borderRadius: "5px",
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        top: 0,
                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 1))`,
                    }}
                >
                    <Box
                        className={styles.noselect}
                        sx={{
                            position: "absolute",
                            left: 0,
                            bottom: 15,
                        }}
                    >
                        <Typography
                            id="questionIndex"
                            variant="subtitle1"
                            sx={{
                                color: theme.palette.wmColor.main,
                                fontWeight: "700",
                                pl: theme.pLeft.secondary,
                                fontSize: {
                                    xs: "0.8rem",
                                    md: desktopQuestionCount,
                                },
                                textTransform: "uppercase",
                                textShadow: "1px 1px #000000",
                            }}
                        >
                            <span
                                style={{
                                    letterSpacing: "-2px",
                                }}
                            >
                                {questionIndex + 1} / {questionsCount}
                            </span>
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                color: "#FFFFFF",
                                fontSize: {
                                    xs: "1.2rem",
                                    md: desktopQuestionFontSize,
                                },
                                pl: "7px",
                                pr: "7px",
                                textShadow: "black 0.5px 0.5px 0.5px",
                                lineHeight: "1",
                                fontWeight: 700,
                            }}
                        >
                            {questionObj ? questionObj.question : ""}
                        </Typography>
                    </Box>
                    <IconButton
                        aria-label="Sound"
                        size="small"
                        sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            color: theme.palette.textColor.main,
                            fontSize: "2rem",
                        }}
                        onClick={handlePlaySound}
                    >
                        {playSound ? (
                            <VolumeUpIcon fontSize="inherit" />
                        ) : (
                            <VolumeOffIcon fontSize="inherit" />
                        )}
                    </IconButton>
                    {isDev() && (
                        <IconButton
                            size="small"
                            sx={{
                                position: "absolute",
                                top: 50,
                                right: 0,
                                color: theme.palette.textColor.main,
                                fontSize: "3rem",
                                display: "none",
                            }}
                            onClick={handlePauseGame}
                            title={pauseGame ? "Play Game" : "Pause Game"}
                        >
                            {pauseGame ? (
                                <PlayCircleIcon fontSize="inherit" />
                            ) : (
                                <PauseCircleIcon fontSize="inherit" />
                            )}
                        </IconButton>
                    )}

                    {/* 3. end of answer box */}
                    {questionStart === true && <Timer />}
                </Box>
            </Box>
        </>
    );
}
