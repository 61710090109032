import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Backdrop,
    Box,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";

import {
    AVATAR_SIZE,
    GAME_STATUS,
    SOCKET_ACTIONS,
    SOCKET_RECEIVE_EVENTS,
    hintIcon,
    skipIcon,
    stealIcon,
} from "../../helpers/constant";
import { addPlayTimer } from "../../reducers/TimerReducer";
import useSocketEvents from "../../hooks/useSocketEvents";
import HomeCountdown from "../HomeCountdown/HomeCountdown";
import Player from "../Player/Player";

import FlashOnIcon from "@mui/icons-material/FlashOn";
import { findPlayer, getLabel, isSpectator } from "../../helpers/Global";
import ActiveGames from "../ActiveGames/ActiveGames";
import KeepPlaying from "../KeepPlaying/KeepPlaying";
import CircleIcon from "@mui/icons-material/Circle";

export default function RoundBreak({
    theme,
    players,
    gameStatus,
    me,
    uiLabels,
    round,
}) {
    const dispatch = useDispatch();

    const [nextPlayers, setNextPlayers] = useState(false);

    const roundInfo = useSelector(({ game }) => game.roundInfo);

    const multiplayerCategory = useSelector(
        ({ game }) => game.multiplayerCategory
    );

    useEffect(() => {
        players.forEach((player) => {
            if (player.spectator) {
                setNextPlayers(true);
                return false;
            }
        });
    }, []);

    // receive in-game // question timer
    const handleRoundCountdown = useCallback(
        (timer) => {
            dispatch(addPlayTimer(timer));
        },
        [dispatch]
    );

    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.ROUND_COUNTDOWN,
        null,
        handleRoundCountdown
    );

    const getEndRoundMessage = useCallback(() => {
        const sx = {
            fontSize: { xs: "1.6rem", md: "1.6rem" },
            fontWeight: 700,
            width: "100%",
            textAlign: "center",
        };
        if (isSpectator(me)) {
            return (
                <>
                    <Typography variant="body1" sx={sx}>
                        {getLabel(
                            uiLabels,
                            "labelBetterLuckNextTime",
                            "Better luck next time"
                        )}
                        !
                    </Typography>
                    <Box display={"flex"} justifyContent={"center"}>
                        <Player
                            showScore={true}
                            player={findPlayer(players, me.playerId)}
                            minWidth={{
                                xs: "120px !important",
                                md: "160px !important",
                                lg: "120px !important",
                            }}
                            sx={{
                                avatar: {
                                    maxWidth: "100%",
                                    size: AVATAR_SIZE.endScreen,
                                },
                            }}
                        />
                    </Box>
                    <Typography variant="body1" sx={sx}>
                        {getLabel(
                            uiLabels,
                            "labelStickAroundToSee",
                            "Stick around to see who takes the prize"
                        )}
                        !
                    </Typography>
                </>
            );
        }

        sx.textTransform = "uppercase";

        return (
            <>
                <Typography variant="body1" sx={sx}>
                    {`${getLabel(
                        uiLabels,
                        "labelCongratsMovingTo",
                        `Congrats, You're moving on to Round`
                    )} ${round + 2}!`}
                </Typography>
            </>
        );
    }, [round]);

    const getIcon = useCallback(() => {
        switch (round) {
            case 0:
                return <img src="/image/power-hour.png" alt="Power Hour" />;

            case 1:
                return <img src="/image/rapid-fire.png" alt="Rapid Fire" />;

            default:
                return <img src="/image/showdown.png" alt="Showdown" />;
        }
    }, [round]);

    return (
        <>
            <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"top"}
                sx={{
                    height: "100%",
                    width: "100%",
                    position: "relative",
                    pt: 5,
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                    }}
                >
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: { xs: "20px", md: "28px" },
                            fontWeight: 700,
                            width: "100%",
                            textAlign: "center",
                            textTransform: "uppercase",
                            letterSpacing: 2,
                            fontFamily: theme.font.family,
                        }}
                    >
                        {getLabel(
                            uiLabels,
                            "labelRoundInfoLabel",
                            `Round ${round + 1}`
                        ).replace(/#0/g, round + 1)}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: { xs: "34px", md: "40px" },
                            fontWeight: 700,
                            width: "100%",
                            textAlign: "center",
                            textTransform: "uppercase",
                            letterSpacing: 2,
                            fontFamily: theme.font.family,
                        }}
                    >
                        {roundInfo && roundInfo.title
                            ? getLabel(
                                  uiLabels,
                                  `labelRoundInfoTitle${round + 1}`,
                                  roundInfo.title
                              )
                            : ""}
                    </Typography>

                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        sx={{
                            height: { xs: "100px", md: "150px" },
                        }}
                    >
                        {getIcon()}
                    </Box>

                    <Box>
                        <HomeCountdown
                            css={{ justifyContent: "center" }}
                            listenerEvent={
                                SOCKET_RECEIVE_EVENTS.ROUND_COUNTDOWN
                            }
                            uiLabels={uiLabels}
                        />
                    </Box>

                    {/* Round Info and Tips */}
                    {gameStatus === GAME_STATUS.ROUND_WAITING && (
                        <Box sx={{ mt: 5 }}>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: { xs: "1.6rem", md: "1.6rem" },
                                    fontWeight: 700,
                                    width: "100%",
                                    textAlign: "center",
                                }}
                            >
                                {getLabel(
                                    uiLabels,
                                    `labelRoundInfoQuestions${round + 1}`,
                                    roundInfo.infoQuestions
                                )}
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: {
                                        xs: "1.6rem",
                                        md: "1.6rem",
                                    },
                                    fontWeight: 700,
                                    width: "100%",
                                    textAlign: "center",
                                }}
                            >
                                {getLabel(
                                    uiLabels,
                                    `labelRoundInfoQualifiers${round + 1}`,
                                    roundInfo.infoQualifiers
                                )}
                            </Typography>
                        </Box>
                    )}

                    {/* tips for round */}
                    {gameStatus === GAME_STATUS.ROUND_WAITING && (
                        <Box
                            sx={{
                                p: 3,
                            }}
                        >
                            <Typography variant="body1">
                                {`${getLabel(
                                    uiLabels,
                                    "labelTipsForRound",
                                    `Tips for Round ${round + 1}`
                                ).replace(/#0/g, round + 1)}:`}
                            </Typography>

                            <List>
                                {roundInfo.tips.map((item, index) => {
                                    let tip = getLabel(
                                        uiLabels,
                                        `labelRoundInfoTips${
                                            round + 1
                                        }_${index}`,
                                        item
                                    );

                                    tip = tip.replace(/#0/g, stealIcon);
                                    tip = tip.replace(/#1/g, skipIcon);
                                    tip = tip.replace(/#2/g, hintIcon);

                                    return (
                                        <ListItem key={index} sx={{ p: 0 }}>
                                            <ListItemIcon>
                                                <CircleIcon
                                                    sx={{
                                                        color: "white",
                                                        fontSize: "0.6rem",
                                                    }}
                                                />
                                            </ListItemIcon>
                                            <Typography
                                                variant="body1"
                                                dangerouslySetInnerHTML={{
                                                    __html: `${tip}`,
                                                }}
                                            ></Typography>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Box>
                    )}

                    {/* message after round ends */}
                    {gameStatus === GAME_STATUS.ROUND_ENDING && (
                        <Box
                            sx={{
                                mt: 2,
                                display: roundInfo ? "block" : "block",
                                px: { xs: 2, md: 0 },
                            }}
                        >
                            {getEndRoundMessage()}
                            <Box
                                display={"flex"}
                                justifyContent={"center"}
                                sx={{
                                    mt: 3,
                                    width: "100%",
                                    position: "relative",
                                    flexFlow: "wrap",
                                }}
                            >
                                {nextPlayers &&
                                    players.map((player) => {
                                        if (player.spectator) return;

                                        return (
                                            <Box
                                                display={"flex"}
                                                justifyContent={"center"}
                                                key={player.id}
                                            >
                                                <Player
                                                    showScore={true}
                                                    player={player}
                                                    minWidth={{
                                                        xs: "120px !important",
                                                        md: "160px !important",
                                                        lg: "120px !important",
                                                    }}
                                                    sx={{
                                                        avatar: {
                                                            maxWidth: "100%",
                                                            size: AVATAR_SIZE.other,
                                                        },
                                                    }}
                                                />
                                            </Box>
                                        );
                                    })}
                            </Box>

                            {/* Keep playing section for spectators */}
                            {isSpectator(me) && (
                                <Box sx={{ mt: 5 }}>
                                    <KeepPlaying
                                        uiLabels={uiLabels}
                                        theme={theme}
                                        multiplayerCategory={
                                            multiplayerCategory
                                        }
                                        sx={null}
                                    />
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    );
}
