import React from "react";
import cssStyle from "./MojoPointsLegend.module.css";
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { getLabel } from "../../helpers/Global";
import { coin, hintIcon, skipIcon, stealIcon } from "../../helpers/constant";

export default function MojoPointsLegend({ uiLabels, theme }) {
    const battleLegends = [
        {
            header: "Ranking",
            items: [
                {
                    text: getLabel(uiLabels, "label1st", "1st Place"),
                    points: "1000",
                },
                {
                    text: getLabel(uiLabels, "label2nd", "2nd Place"),
                    points: "800",
                },
                {
                    text: getLabel(uiLabels, "label3rd", "3rd Place"),
                    points: "600",
                },
                {
                    text: getLabel(uiLabels, "label4th", "4th Place"),
                    points: "500",
                },
                {
                    text: getLabel(uiLabels, "label5th", "5th Place"),
                    points: "400",
                },
                {
                    text: getLabel(uiLabels, "label6th", "6th Place"),
                    points: "300",
                },
                {
                    text: getLabel(uiLabels, "label7th", "7th Place"),
                    points: "250",
                },
                {
                    text: getLabel(uiLabels, "label8th", "8th Place"),
                    points: "200",
                },
                {
                    text: getLabel(uiLabels, "label9th", "9th Place"),
                    points: "150",
                },
                {
                    text: getLabel(uiLabels, "label10th", "10th+ Place"),
                    points: "100",
                },
            ],
        },
        {
            header: "Achievements",
            items: [
                {
                    text: getLabel(
                        uiLabels,
                        "label3DaysInRow",
                        "Play 3 Days in a row"
                    ),
                    points: "100",
                },
                {
                    text: getLabel(
                        uiLabels,
                        "label5DaysInRow",
                        "Play 5 Days in a row"
                    ),
                    points: "200",
                },
                {
                    text: getLabel(
                        uiLabels,
                        "label5GoodInRow",
                        "5 good answer in a row"
                    ),
                    points: "50",
                },
                {
                    text: getLabel(
                        uiLabels,
                        "label10GoodInRow",
                        "10 good answer in a row"
                    ),
                    points: "150",
                },
                {
                    text: getLabel(
                        uiLabels,
                        "label20GoodInRow",
                        "20 good answer in a row"
                    ),
                    points: "500",
                },
            ],
        },

        {
            header: "Power ups",
            items: [
                {
                    text: `${skipIcon} ${getLabel(
                        uiLabels,
                        "btnSKIP",
                        "SKIP"
                    ).toUpperCase()}`,
                    points: "-250",
                },
                {
                    text: `${stealIcon} ${getLabel(
                        uiLabels,
                        "btnSTEAL",
                        "STEAL"
                    ).toUpperCase()}`,
                    points: "-250",
                },
                {
                    text: `${hintIcon} ${getLabel(
                        uiLabels,
                        "btnHINT",
                        "HINT"
                    ).toUpperCase()}`,
                    points: "-250",
                },
            ],
        },
    ];

    const categoryLegends = [
        {
            header: "Ranking",
            items: [
                {
                    text: getLabel(uiLabels, "label1st", "1st Place"),
                    points: "250",
                },
                {
                    text: getLabel(uiLabels, "label2nd", "2nd Place"),
                    points: "200",
                },
                {
                    text: getLabel(uiLabels, "label3rd", "3rd Place"),
                    points: "150",
                },
                {
                    text: getLabel(uiLabels, "label4th", "4th Place"),
                    points: "125",
                },
                {
                    text: getLabel(uiLabels, "label5th", "5th Place"),
                    points: "100",
                },
                {
                    text: getLabel(uiLabels, "label6th2", "6th+ Place"),
                    points: "50",
                },
            ],
        },
        {
            header: "Achievements",
            items: [
                {
                    text: getLabel(uiLabels, "label3inRow", "3 games in a row"),
                    points: "50",
                },
                {
                    text: getLabel(uiLabels, "label5inRow", "5 games in a row"),
                    points: "100",
                },
                {
                    text: getLabel(
                        uiLabels,
                        "label10inRow",
                        "10 games in a row"
                    ),
                    points: "250",
                },
                {
                    text: getLabel(
                        uiLabels,
                        "label5GoodInRow",
                        "5 good answer in a row"
                    ),
                    points: "20",
                },
                {
                    text: getLabel(
                        uiLabels,
                        "label10GoodInRow",
                        "10 good answer in a row"
                    ),
                    points: "50",
                },
                {
                    text: getLabel(
                        uiLabels,
                        "label20GoodInRow",
                        "20 good answer in a row"
                    ),
                    points: "200",
                },
            ],
        },
        {
            header: "Extra",
            items: [
                {
                    text: getLabel(
                        uiLabels,
                        "labelInviteFriends",
                        "Invite friends"
                    ),
                    points: "500",
                },
                {
                    text: getLabel(
                        uiLabels,
                        "labelSignUpBonus",
                        "Sign-Up Bonus"
                    ),
                    points: "500",
                },
                {
                    text: getLabel(
                        uiLabels,
                        "labelPlayATriviaGame",
                        "Play a Trivia game"
                    ),
                    points: "20",
                },
            ],
        },
    ];

    const extraLegends = [];

    function Legends(props) {
        return (
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell
                            className={`${cssStyle.tdHeader} ${cssStyle.titleHeader}`}
                        >
                            {props.header ?? ""}
                        </TableCell>
                        <TableCell
                            align="center"
                            className={`${cssStyle.tdHeader} `}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            dangerouslySetInnerHTML={{
                                __html: `${coin}&nbsp;MojoPoints`,
                            }}
                        ></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.item.map((item, index) => {
                        const arr = [];

                        if (item.header) {
                            arr.push(
                                <TableRow key={`row-${index}`}>
                                    <TableCell
                                        className={`${cssStyle.tdBorder} ${cssStyle.legendHeader}`}
                                        sx={theme.home.leaderboard.tdPlayer}
                                        colSpan={2}
                                    >
                                        {item.header}
                                    </TableCell>
                                </TableRow>
                            );
                        }

                        item.items.forEach((subitem) => {
                            arr.push(
                                <TableRow>
                                    <TableCell
                                        className={`${cssStyle.tdBorder} `}
                                        sx={theme.home.leaderboard.tdPlayer}
                                        dangerouslySetInnerHTML={{
                                            __html: `${subitem.text}`,
                                        }}
                                    ></TableCell>
                                    <TableCell
                                        className={`${cssStyle.tdBorder}`}
                                    >
                                        <Typography
                                            noWrap
                                            variant="body1"
                                            sx={{
                                                fontWeight: 400,
                                                color: "#FFFFFF",
                                                fontSize: "14px",
                                                textAlign: "center",
                                            }}
                                        >
                                            {subitem.points}
                                        </Typography>{" "}
                                    </TableCell>
                                </TableRow>
                            );
                        });

                        return arr;
                    })}
                </TableBody>
            </Table>
        );
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <Legends item={battleLegends} header={"Trivia Battles"} />
                </Grid>
                <Grid item xs={12} md={6} container spacing={0}>
                    <Grid item xs={12} sx={{ mt: { xs: 3, md: 0 } }}>
                        <Legends
                            item={categoryLegends}
                            header={"Category Games"}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
