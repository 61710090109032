/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { Box, Container, CssBaseline, Grid } from "@mui/material";

import Players from "../../components/Players/Players";
import LobbySettings from "../../components/LobbySettings/LobbySettings";
import styles from "./Lobby.module.css";

import HowTo from "../../components/HowTo/HowTo";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";

import useSocketEvents from "../../hooks/useSocketEvents";

import {
    SOCKET_ACTIONS,
    SOCKET_SEND_EVENTS,
    SOCKET_RECEIVE_EVENTS,
    GAME_STATUS,
} from "../../helpers/constant";
import SnackBar from "../../helpers/SnackBar";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import CountdownHeader from "../../components/CountdownHeader/CountdownHeader";
import HomeCountdown from "../../components/HomeCountdown/HomeCountdown";

import AlertDialog from "../../components/AlertDialog/AlertDialog";
import { addAlert } from "../../reducers/AlertReducer";
import AlertCookie from "../../components/AlertCookie/AlertCookie";
import { getLabel, toggleMojoPoints } from "../../helpers/Global";
import RoundBreak from "../../components/RoundBreak/RoundBreak";
import LobbyHeader from "../../components/LobbyHeader/LobbyHeader";
import Instructions from "../../components/Instructions/Instructions";

const Lobby = ({ uiLabels }) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const howToContainer = useRef();
    const socketFromStore = useSelector(({ socket }) => socket.socket);
    const questions = useSelector(({ game }) => game.questions);
    const message = useSelector(({ message }) => message.message);
    const openSnackbar = useSelector(({ message }) => message.openSnackbar);
    const error = useSelector(({ message }) => message.error);
    const gameStatus = useSelector(({ game }) => game.gameStatus);
    const round = useSelector(({ game }) => game.round);

    const requiredPoints = useSelector(({ game }) => game.requiredPoints);

    const player = useSelector(({ player }) => player);
    const players = useSelector(({ game }) => {
        return game.gamePlayers;
    });

    useEffect(() => {
        toggleMojoPoints(true);
    }, []);

    useEffect(() => {
        if (!questions || questions.length === 0) {
            return;
        }

        socketFromStore.emit(SOCKET_SEND_EVENTS.JOIN_ROOM, player.playerId);
    }, [questions]);

    //add event for insufficient points to play
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.INSUFFICIENT_POINTS,
        null,
        ({ message, requiredPoints, points }) => {
            message = message.replace(/#0/g, requiredPoints);
            message = message.replace(/#1/g, points);
            dispatch(
                addAlert({
                    message: message,
                    buttons: [
                        {
                            text: getLabel(uiLabels, "btnOk", "Ok"),
                            callback: () => {
                                window.parent.location.href = `${process.env.REACT_APP_WM_URL}/triviaHome`;
                            },
                        },
                    ],
                })
            );
        }
    );

    return (
        /* if no error, lobby page, else errorPage */
        error === "" ? (
            <>
                <Container style={{ padding: "0", maxWidth: "100%" }}>
                    {player &&
                        player.playerId &&
                        !player.hasConsent &&
                        !isNaN(player.playerId) && (
                            <AlertCookie uiLabels={uiLabels} />
                        )}
                    {gameStatus === GAME_STATUS.COUNTDOWN && (
                        <Box
                            sx={{
                                ml: 0,
                                height: "100%",
                                pt: { xs: "0", md: "2vh" },
                            }}
                            spacing={{ xs: "0" }}
                            className={styles.backgroundColor}
                        >
                            <Box>
                                <LobbyHeader
                                    theme={theme}
                                    uiLabels={uiLabels}
                                    header2={
                                        requiredPoints && requiredPoints > 0
                                            ? getLabel(
                                                  uiLabels,
                                                  "labelCashBattle",
                                                  "Trivia Cash Battle"
                                              )
                                            : getLabel(
                                                  uiLabels,
                                                  "labelPointsBattle",
                                                  "Trivia Points Battle"
                                              )
                                    }
                                />
                            </Box>

                            <Box sx={{ px: { xs: "0px", md: "15%" } }}>
                                <Players players={players} />
                            </Box>

                            <Box
                                sx={{
                                    width: { xs: "100%" },
                                    mt: 5,
                                }}
                            >
                                <CountdownHeader
                                    gameStatus={gameStatus}
                                    player={player}
                                    textHeader={`${getLabel(
                                        uiLabels,
                                        "labelStartsIn",
                                        "Starts In"
                                    )}:`}
                                    css={{ textAlign: "center" }}
                                />
                                <HomeCountdown
                                    gameStatus={gameStatus}
                                    css={{ justifyContent: "center" }}
                                    listenerEvent={
                                        SOCKET_RECEIVE_EVENTS.START_COUNTDOWN
                                    }
                                    uiLabels={uiLabels}
                                />
                            </Box>
                            <Box
                                sx={{
                                    mx: { xs: "0px", md: "10%", lg: "25%" },
                                    mt: 5,
                                }}
                            >
                                <Instructions uiLabels={uiLabels} />
                            </Box>
                        </Box>
                    )}
                    {[
                        GAME_STATUS.ROUND_ENDING,
                        GAME_STATUS.ROUND_WAITING,
                    ].includes(gameStatus) && (
                        <Box sx={{ px: { xs: "10px", md: "20%", lg: "25%" } }}>
                            <RoundBreak
                                gameStatus={gameStatus}
                                players={players}
                                me={player}
                                uiLabels={uiLabels}
                                theme={theme}
                                round={round}
                            />
                        </Box>
                    )}
                    {message !== "" && (
                        <SnackBar open={openSnackbar} message={message} />
                    )}
                    <AlertDialog />
                </Container>
            </>
        ) : (
            <ErrorPage />
        )
    );
};

export default Lobby;
