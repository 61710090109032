import React from "react";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import { GAME_STATUS } from "../../helpers/constant";
import { getLabel } from "../../helpers/Global";

export default function ButtonJoin({ player, gameStatus, label }) {
    const theme = useTheme();
    const navigate = useNavigate();

    const handlePlayButton = (e) => {
        if (player.playerQueue) {
            navigate("/lobby");
            return;
        }

        //if player is not in queue, then refresh page to try to join queue again
        //update parent url as well with roomId
        let message = { action: "refreshPage" };
        //method to send message to parent outside iframe
        window.top.postMessage(message, "*");
    };

    return (
        <>
            {player.playerQueue &&
                [GAME_STATUS.COUNTDOWN, GAME_STATUS.QUESTIONS].includes(
                    gameStatus
                ) && (
                    <Button
                        variant="contained"
                        sx={theme.home.login.joinCatchUpButton}
                        onClick={handlePlayButton}
                    >
                        {label}
                    </Button>
                )}
        </>
    );
}
