import React from "react";
import { Typography, Box } from "@mui/material";
import { getLabel } from "../../helpers/Global";

export default function HomeHeader({ uiLabels, requiredPoints, theme }) {
    return (
        <>
            <Box>
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: { xs: "34px", md: "56px" },
                        fontWeight: 700,
                        width: "100%",
                        textAlign: "center",
                        textTransform: "uppercase",
                        letterSpacing: 2,
                        fontFamily: theme.font.family,
                    }}
                >
                    {requiredPoints && requiredPoints > 0
                        ? getLabel(uiLabels, "labelCashBattle", "Cash Battle")
                        : getLabel(
                              uiLabels,
                              "labelPointsBattle",
                              "Daily Points Battle"
                          )}
                </Typography>
            </Box>
        </>
    );
}
