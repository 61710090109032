import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function CountdownTime({
    number = 0,
    type = "",
    isActive = false,
    padding = "0px",
    mx = 0,
}) {
    const theme = useTheme();
    const clockWidth = "66px";
    const clockHeight = "56px";
    return (
        <>
            <Box
                sx={{
                    padding: padding,
                    mx: mx,
                    width: clockWidth,
                }}
            >
                <Box
                    className="TEST"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        width: "100%",
                        height: clockHeight,
                        backgroundColor: isActive
                            ? theme.home.timer.bg.active
                            : theme.home.timer.bg.inactive,
                        borderRadius: "4px",
                    }}
                >
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: { xs: "34px", md: "40px" },
                            fontWeight: 600,
                            width: "100%",
                            textAlign: "center",
                            textShadow: "0.5px 0.5px 0.5px #666",
                        }}
                    >
                        {number}
                    </Typography>
                </Box>
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: { xs: "0.75rem", md: "0.8rem" },
                        fontWeight: 500,
                        width: "100%",
                        textAlign: "center",
                        opacity: "70%",
                        textTransform: "uppercase",
                    }}
                >
                    {type}
                </Typography>
            </Box>
        </>
    );
}
